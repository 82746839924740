import React from "react";

import "./hero-headline.scss";

interface Props {
  alt?: string;
}

const HeroHeadline: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  alt,
}) => (
  <div className="hero-headline">
     
    <div  style = {{ color: 'black', fontWeight: 'bold'}}>
    {children} <img style={{ width: 25, marginBottom: -20 }} src={require('../../icons/pointvirgule.png') } alt="Example" />
    </div>
   
  </div>
);

export default HeroHeadline;
