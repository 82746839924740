import React from 'react';
import { Tag } from '../../model';
import classNames from 'classnames';
import { ConfigData } from '../../config';

const badgeClass = (type: string, config: ConfigData) => {
  if (!config.rings.includes(type)) {
    return type;
  }
  return ['first', 'second', 'third', 'fourth'][config.rings.indexOf(type)];
};

type ItemTagsProps = {
  tags?: Tag[];
  config: ConfigData; 
};

export default function ItemTags({ tags, config }: ItemTagsProps) {
  if (!tags) {
    return null;
  }

  return (
    <div className="markdown" style={{ display: 'flex', alignItems: 'center' }}>
      <img style={{ width: 30, marginLeft: 15 }} src={require('../../icons/delta.png')} alt="Delta" />
      <div style={{ marginLeft: 15, display: 'flex', flexWrap: 'wrap', maxWidth: '100%' }}>
        {tags.map((tag, id) => (
          <span
            key={tag}
            className={classNames('badge', badgeClass(tag, config))}
            style={{ marginRight: 5, marginBottom: 5, display: 'inline-block' }}
          >
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
}
