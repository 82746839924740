import React from "react";

import { radarName } from "../../config";
import { useMessages } from "../../context/MessagesContext";
import Fadeable from "../Fadeable/Fadeable";
import HeroHeadline from "../HeroHeadline/HeroHeadline";
import SetTitle from "../SetTitle";
import "./PageHelp.scss";

interface Props {
  leaving: boolean;
  onLeave: () => void;
}

const PageHelp: React.FC<Props> = ({ leaving, onLeave }) => {
  const { pageHelp } = useMessages();

  if (pageHelp) {
    const {
      headlinePrefix,
    } = pageHelp;
    const title = `${headlinePrefix || "How to use the"} ${radarName}`;
    return (

      <Fadeable leaving={leaving} onLeave={onLeave}>
       <SetTitle title={title} />
       <HeroHeadline>{title}</HeroHeadline>
       <div className="root">

       <div className="area" >
          <div className="area-text">
            <div className="title">
              Unleash Innovation with Elyadata Technology Radar           
            </div>
            <div className="main-text">
              Elevate your decision-making, drive growth, and unlock new possibilities for your business.    The Elyadata Technology Radar provides valuable insights and analysis on the latest technology trends, tools, and frameworks. By leveraging this information, you can make informed decisions about adopting new technologies, optimizing existing systems, and aligning your technology strategy with your business goals. It empowers you to stay ahead of the curve and make strategic choices that can have a significant impact on your business.        
            </div>
          </div>
          <div className="rectangle-area">
            <div className="rectangle-pink">
            </div>
            <div className="rectangle-blue">
              <div className="square-blue"></div>
            </div>
          </div>
        </div>
        </div>     
      </Fadeable>
    );
  }

  return null;
};

export default PageHelp;
