import classNames from "classnames";
import React from "react";

import { getItemPageNames, isMobileViewport } from "../../config";
import { useMessages } from "../../context/MessagesContext";
import { Item } from "../../model";
import FooterEnd from "../FooterEnd/FooterEnd";
import "./footer.scss";

interface Props {
  items: Item[];
  pageName: string;
}

const Footer: React.FC<Props> = ({ items, pageName }) => {
  const { footerFootnote } = useMessages();

  return (
    <div
      className={classNames("footer", {
        "is-hidden":
          !isMobileViewport() && getItemPageNames(items).includes(pageName),
      })}
    >
      {footerFootnote && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img style={{ width: 30, marginLeft: 15 }} src={require('../../icons/hashtag.png')} alt="Example" />
          <div className="footnote" style={{ fontSize: 16, marginLeft: 15 }}>
            We help business leaders transform their organisations and ensure continued success by using advanced technology and best practices.
          </div>
        </div>
      )}
      <FooterEnd />
    </div>
  );
};

export default Footer;
